


















































































































































































































































































import { debounceProcess } from "@/helpers/debounce";
import { ResponsePagination } from "@/models/constant/interface/common.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { Messages } from "@/models/enums/messages.enum";
import { ContactData } from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  LineDtosDetailBastHasBeenCreated,
  RequestBastHasBeenCreated,
  ResponseDetailBastHasBeenCreated,
  TypeStateBastBalikan,
} from "@/models/interface/salesOrder.interface";
import { IUserRole } from "@/models/interface/user.interface";
import { contactServices } from "@/services/contact.service";
import { salesOrderServices } from "@/services/salesorder.service";
import moment, { Moment } from "moment";
import Vue from "vue";
import MNotificationVue from "@/mixins/MNotification.vue";
import { mapGetters } from "vuex";

interface DataForm {
  bastNumber: string;
  bastDate: string;
  billingType: string;
  contractPeriod: Moment[];
  salesOrderNumber: string;
  customerName: string;
  customerShipAddress: string;
}

interface DataSourceModal {
  no: number;
  listAccessories: string;
  exist: boolean;
  key: number;
}

export default Vue.extend({
  mixins: [MNotificationVue],
  data() {
    this.getListSales = debounceProcess(this.getListSales, 200);
    return {
      TypeStateBastBalikan: TypeStateBastBalikan,
      listRoles: [] as IUserRole[],
      status: TypeStateBastBalikan.New as TypeStateBastBalikan,
      DEFAULT_DATE_FORMAT,
      form: this.$form.createForm(this, { name: "viewPrintBAST" }),
      id: "" as string,
      indexRow: 0 as number,
      dataSource: [] as LineDtosDetailBastHasBeenCreated[],
      dataSourceModal: [] as DataSourceModal[],
      tempAccessories: [] as string[],
      modalOpen: false as boolean,
      page: this.page || (1 as number),
      limit: this.limit || (10 as number),
      totalData: 0 as number,
      dataListSales: [] as ContactData[],
      firstLoad: false as boolean,
      doDate: "" as string,
      loading: {
        sales: false,
        submit: false,
        update: false,
        posting: false,
        tableModal: false,
      },
      dataDetail: {} as ResponseDetailBastHasBeenCreated,
      dataForm: {
        bastNumber: "",
        bastDate: "",
        billingType: "",
        contractPeriod: [],
        salesOrderNumber: "",
        customerName: "",
        customerShipAddress: "",
      } as DataForm,
      columnsTable: [
        {
          title: "Unit Code",
          dataIndex: "unitCode",
          key: "unitCode",
          width: 150,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_serial_number"),
          dataIndex: "serialNumber",
          key: "serialNumber",
          width: 150,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_location"),
          dataIndex: "location",
          key: "location",
          width: 150,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: "Merk",
          dataIndex: "merk",
          key: "merk",
          width: 150,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: "Type",
          dataIndex: "type",
          key: "type",
          width: 150,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: "Capacity",
          dataIndex: "capacity",
          key: "capacity",
          width: 150,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: "Specifications",
          dataIndex: "specification",
          key: "specification",
          width: 150,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: "Parent",
          dataIndex: "parent",
          key: "parent",
          width: 150,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: "Accessories",
          dataIndex: "operation",
          key: "operation",
          width: 150,
          align: "center",
          responsiveMenu: [
            {
              title: "View Data",
              icon: "eye",
              triggerMenu: this.handleAddData,
            },
          ],
          scopedSlots: { customRender: "operation" },
        },
      ],
      columnsTableModal: [
        {
          title: "No",
          dataIndex: "no",
          key: "no",
          width: 150,
          scopedSlots: { customRender: "isNull" },
          responsiveColCheckBox: [
            {
              name: "exist",
              disabled: true,
            },
          ],
        },
        {
          title: "List Accessories",
          dataIndex: "listAccessories",
          key: "listAccessories",
          width: 150,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: "Exist",
          dataIndex: "exist",
          key: "exist",
          width: 150,
          scopedSlots: { customRender: "exist" },
        },
      ],
      formRules: {
        sales: {
          label: "Sales",
          name: "sales",
          placeholder: "Insert Sales",
          decorator: [
            "sales",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        bastDateReceivedBySales: {
          label: "BAST Date Received By Sales",
          name: "bastDateReceivedBySales",
          placeholder: "Insert BAST Date Received By Sales",
          decorator: [
            "bastDateReceivedBySales",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        billingDate: {
          label: "lbl_starting_billing_date",
          name: "billingDate",
          placeholder: "lbl_starting_billing_date_placeholder",
          decorator: [
            "billingDate",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        contractPeriod: {
          label: "lbl_contract_period",
          name: "contractPeriod",
          placeholder: "lbl_contract_period_placeholder",
          decorator: [
            "contractPeriod",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        bastDateReceivedByCustomer: {
          label: "BAST Date Received By Customer",
          name: "bastDateReceivedByCustomer",
          placeholder: "Insert BAST Date Received By Customer",
          decorator: [
            "bastDateReceivedByCustomer",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        position: {
          label: "lbl_position",
          name: "position",
          placeholder: "lbl_position_placeholder",
          decorator: [
            "position",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        receivedBy: {
          label: "lbl_received_by",
          name: "receivedBy",
          placeholder: "lbl_received_by_placeholder",
          decorator: [
            "receivedBy",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        bastDateBack: {
          label: "lbl_bast_date_back",
          name: "bastDateBack",
          placeholder: "lbl_bast_date_back_placeholder",
          decorator: [
            "bastDateBack",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        approvedBy: {
          label: "lbl_approved_by",
          name: "approvedBy",
          placeholder: "lbl_approved_by_placeholder",
          decorator: [
            "approvedBy",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        description: {
          label: "lbl_description",
          name: "description",
          placeholder: "lbl_description_placeholder",
          decorator: [
            "description",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
      },
    };
  },
  watch: {
    dataDetail: {
      immediate: true,
      deep: true,
      handler() {
        this.assignSales();
      },
    },
    dataListSales: {
      handler() {
        this.assignSales();
      },
    },
    "$store.state.authStore.authData": {
      immediate: true,
      deep: true,
      handler() {
        if (
          this.$store.state.authStore.authData &&
          this.$store.state.authStore.authData.roles
        ) {
          this.listRoles = this.$store.state.authStore.authData.roles;
        }
        if (this.status === TypeStateBastBalikan.POSTED) {
          this.listRoles = [];
        }
      },
    },
    status: {
      immediate: true,
      deep: true,
      handler() {
        if (this.status === TypeStateBastBalikan.POSTED) {
          this.listRoles = [];
        }
      },
    },
    listRoles: {
      handler() {
        if (this.isAdministrator) {
          const needMandatory = [
            "sales",
            "bastDateReceivedBySales",
            "billingDate",
            "contractPeriod",
            "bastDateReceivedByCustomer",
            "receivedBy",
            "position",
            "bastDateBack",
            "approvedBy",
            "description",
          ];
          this.changedFormToRequired(needMandatory);
        } else if (this.isSales) {
          const needMandatory = ["sales", "receivedBy", "billingDate"];
          this.changedFormToRequired(needMandatory);
        } else if (this.isOperational) {
          const needMandatory = [
            "bastDateReceivedByCustomer",
            "receivedBy",
            "position",
            "dateBack",
            "approvedBy",
            "description",
          ];
          this.changedFormToRequired(needMandatory);
        }
      },
    },
  },
  methods: {
    changedFormToRequired(needMandatory: string[]) {
      needMandatory.forEach(dataForeach => {
        this.formRules[dataForeach].decorator = [
          dataForeach,
          {
            rules: [
              {
                required: true,
                message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
              },
            ],
          },
        ];
      });
    },
    disabledForm() {
      if (this.status === TypeStateBastBalikan.POSTED) return true;
      else if (this.isSales || this.isAdministrator) return false;
      else return true;
    },
    disabledRangePicker(current) {
      return current < moment(this.doDate, DEFAULT_DATE_FORMAT);
    },
    disabledDate(current) {
      // Can not select days before bast date and future date from today
      // update can not select days before do date and future date from today
      return (
        current < moment(this.doDate, DEFAULT_DATE_FORMAT) ||
        current > moment().endOf("day")
      );
    },
    handleAddData(response) {
      this.indexRow = response.key;
      this.tempAccessories = [];
      this.dataSourceModal = this.dataSourceModal.map(dataMap => {
        if (
          this.dataSource[this.indexRow].accessories.includes(
            dataMap.listAccessories
          )
        ) {
          this.tempAccessories.push(dataMap.listAccessories);
          return { ...dataMap, exist: true };
        } else {
          return { ...dataMap, exist: false };
        }
      });
      this.modalOpen = true;
    },
    handleCancelModal() {
      this.modalOpen = false;
    },
    handleCheckBoxTable(checked, key, nameColCheckbox, _value): void {
      this.dataSourceModal[key][nameColCheckbox] = checked;
      if (checked) {
        this.tempAccessories.push(this.dataSourceModal[key].listAccessories);
      } else {
        this.tempAccessories = this.tempAccessories.filter(
          dataFilter => dataFilter !== this.dataSourceModal[key].listAccessories
        );
      }
    },
    getListSales(valueSearch) {
      const params: RequestQueryParamsModel = {
        limit: 10,
        page: 0,
        search: "employee~true_AND_active~true_AND_employeeData.position~Sales",
      };
      if (valueSearch)
        params.search += `_AND_firstName~*${valueSearch}*_OR_lastName~*${valueSearch}*`;
      this.loading.sales = true;
      contactServices
        .listContactData(params)
        .then(response => (this.dataListSales = response.data))
        .finally(() => (this.loading.sales = false));
    },
    assignSales() {
      if (
        this.dataDetail &&
        Object.keys(this.dataDetail).length > 0 &&
        this.dataListSales.length > 0 &&
        !this.firstLoad
      ) {
        this.dataListSales = [
          ...this.dataListSales,
          { fullName: this.dataDetail.salesName, id: this.dataDetail.salesId },
        ].filter(
          (dataFilter, index, self) =>
            index === self.findIndex(t => t.id === dataFilter.id)
        );
        this.firstLoad = true;
      }
    },
    getListAccessories() {
      salesOrderServices.getListAccessories().then(data => {
        if (data.length > 0) {
          data.forEach((item, index) => {
            this.dataSourceModal.push({
              no: index + 1,
              listAccessories: item,
              exist: false,
              key: index,
            });
          });
        }
      });
    },
    assignDoDate(tempSplit, response) {
      if (response.doDate) this.doDate = response.doDate;
      else this.doDate = tempSplit.length > 0 ? tempSplit[0] : undefined;
    },
    checkDate(value) {
      if (value) return moment(value, DEFAULT_DATE_FORMAT);
      else return undefined;
    },
    getDetailBastHasBeenCreated() {
      const params: RequestQueryParamsModel = {};
      salesOrderServices
        .getDetailBastHasBeenCreated(params, this.id)
        .then(response => {
          this.dataDetail = response;
          const tempContractPeriod: Moment[] = [];
          const tempSplit = response.contractPeriod.split(" - ");
          if (response.contractPeriod) {
            tempContractPeriod.push(moment(tempSplit[0], DEFAULT_DATE_FORMAT));
            tempContractPeriod.push(moment(tempSplit[1], DEFAULT_DATE_FORMAT));
          }
          this.assignDoDate(tempSplit, response);
          this.status = response.state;
          this.totalData = response.lineDTOS.length;
          this.dataForm.bastNumber = response.bastNumber;
          this.dataForm.bastDate = response.bastDate;
          this.dataForm.billingType = response.billingType;
          this.dataForm.salesOrderNumber = response.soNumber;
          this.dataForm.customerName = response.customerName;
          this.dataForm.customerShipAddress = response.customerShipAddress;
          this.dataListSales = [
            ...this.dataListSales,
            { fullName: response.salesName, id: response.salesId },
          ].filter(
            (dataFilter, index, self) =>
              index === self.findIndex(t => t.id === dataFilter.id)
          );
          this.form.setFieldsValue({
            contractPeriod: tempContractPeriod,
            sales: response.salesId,
            bastDateReceivedBySales: this.checkDate(
              response.receivedBySalesDate
            ),
            billingDate: this.checkDate(response.billingDate),
            bastDateReceivedByCustomer: this.checkDate(
              response.receivedByCustomerDate
            ),
            receivedBy: response.receivedByCustomer,
            position: response.position,
            bastDateBack: this.checkDate(response.dateBack),
            approvedBy: response.approvedBy,
            description: response.description,
          });
          this.dataSource = response.lineDTOS.map((dataMap, index) => {
            return { ...dataMap, key: index };
          });
        });
    },
    handleBack() {
      this.$router.push("/sales/serah-terima/balikan");
    },
    handleNotification(bastNumber) {
      this.showNotifSuccess(`BAST Number: ${bastNumber}`);
      this.showNotifSuccess("notif_update_success");
    },
    onSubmit(type) {
      this.form.validateFields((err, res) => {
        if (err) return;
        let tempContractPeriod: string[] = [];
        if (res.contractPeriod && res.contractPeriod.length > 0) {
          tempContractPeriod = [
            moment(res.contractPeriod[0]).format("YYYY-MM-DD"),
            moment(res.contractPeriod[1]).format("YYYY-MM-DD"),
          ];
        }
        const payload: RequestBastHasBeenCreated = {
          isSales: this.isSales || this.isAdministrator ? true : false,
          receivedByCustomerDate: res.bastDateReceivedByCustomer
            ? moment(res.bastDateReceivedByCustomer).utcOffset("+07").format()
            : "",
          receivedByCustomer: res.receivedBy,
          position: res.position,
          dateBack: res.bastDateBack
            ? moment(res.bastDateBack).format("yyyy-MM-DD")
            : "",
          approvedBy: res.approvedBy,
          description: res.description,
          salesId: res.sales,
          receivedBySalesDate: res.bastDateReceivedBySales
            ? moment(res.bastDateReceivedBySales).format("yyyy-MM-DD")
            : "",
          billingDate: res.billingDate
            ? moment(res.billingDate).format("yyyy-MM-DD")
            : "",
          state: TypeStateBastBalikan.New,
          contractPeriod: tempContractPeriod.join(" - "),
          startRent: tempContractPeriod.length > 0 ? tempContractPeriod[0] : "",
          endRent: tempContractPeriod.length > 0 ? tempContractPeriod[1] : "",
        };
        switch (type) {
          case "submit":
            salesOrderServices
              .putBastHasBeenCreated(payload, this.id)
              .then(() => {
                this.handleNotification(this.dataForm.bastNumber);
                this.$router.push("/sales/serah-terima/balikan");
              })
              .catch(() => this.showNotifError("notif_update_fail"));
            break;
          case "update":
            payload.state = this.isOperational
              ? TypeStateBastBalikan.OPERATIONAL_SUBMITTED
              : TypeStateBastBalikan.SALES_SUBMITTED;
            salesOrderServices
              .putBastHasBeenCreated(payload, this.id)
              .then(() => {
                this.handleNotification(this.dataForm.bastNumber);
                this.$router.push("/sales/serah-terima/balikan");
              })
              .catch(() => this.showNotifError("notif_update_fail"));
            break;
          case "posting":
            payload.state = TypeStateBastBalikan.POSTED;
            salesOrderServices
              .putBastHasBeenCreated(payload, this.id)
              .then(() => {
                this.handleNotification(this.dataForm.bastNumber);
                this.$router.push("/sales/serah-terima/balikan");
              })
              .catch(() => this.showNotifError("notif_update_fail"));
            break;
          default:
            break;
        }
      });
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.limit = response.size;
      this.page = 1;
      // this.onSubmit()
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.page = response.page;
      // this.onSubmit()
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
  created() {
    this.getListSales("");
    this.id = this.$route.params.id;
    this.getDetailBastHasBeenCreated();
    this.getListAccessories();
  },
  computed: {
    ...mapGetters({
      getUserMenu: "authStore/GET_USER_MENU",
    }),
    formItemLayout() {
      return {
        labelCol: { span: 10 },
        wrapperCol: { span: 12 },
      };
    },
    isOperational(): boolean {
      return this.getUserMenu.some(menu => menu === "BAST Operation");
    },
    isAdministrator(): boolean {
      return this.listRoles.some(dataSome => dataSome.name === "Administrator");
    },
    isSales(): boolean {
      return this.getUserMenu.some(menu => menu === "BAST Sales");
    },
  },
});
